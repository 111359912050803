<template>
  <div class="about">
    <h1>{{$auth.user.nickname}}</h1>
    <img class="profile" :src="$auth.user.picture"/>
    <p>This is the info we have on you</p>
      <p v-for="property in properties" v-bind:key="property">
        {{property}}: {{$auth.user[property]}}
      </p>
  </div>
</template>

<script>

export default {
  name: 'Profile',
  data() {
    return {
      hiddenProperties: ['profile'],
    };
  },
  computed: {
    properties() {
      return Object.keys(this.$auth.user).filter((property) => !['picture', 'sub', 'email_verified', 'updated_at'].includes(property));
    },
  },
  components: {
  },
  methods: {
  },
};
</script>
<style scoped>
img.profile {
  border-radius: 50%;
}
</style>
